.report {
  .history-export-text {
    font-size: 20px;
    font-weight: bold;
  }

  .ribbon-edit {
    color: black;
    i {
      color: green;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .ribbon-view {
    color: black;
    i {
      color: blue;
      font-size: 15px;
      cursor: pointer;
    }
    // &:hover {
    //   text-decoration: underline;
    // }
  }

  .ribbon-delete {
    i {
      color: red;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}