.table-antd {
  // table {
  //   white-space: pre
  // }

  // thead[class*="ant-table-thead"] th{
  //   background-color: rgba(101,116,205,0.2) !important;
  // }
  
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
  }
  
  .table-summary table > tbody > tr:last-child {
    font-weight: bold;
  }
}

.table-antd-entity {
  table {
    white-space: normal !important;
  }
}