.epg-timing-update {
  // .epg-timing-update-video {
  //   height: 320px !important;
  // }

  .epg-timing-update-table {
    max-height: 700px !important;
    overflow-y: scroll !important;
  }

  .epg-timing-btn-container {
    display: flex;

    .epg-timing-btn {
      background-color: #1565c0;
      color: white;
      width: 40px;
      text-align: center;
      padding: 5px 0;
      border-radius: 3px;
      cursor: pointer;
      margin-right: 5px;
    }
  }

  .epg-current-title-text {
    background-color: black;
    color: yellow;
    padding: 2px 10px 20px 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid white;
  }
}