.banner-position {
  .banner-position-delete {
    i {
      color: red;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .banner-position-edit {
    color: black;
    i {
      color: green;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}