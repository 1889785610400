.menu-item {
  .menu-edit {
    color: black;
    i {
      color: green;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .menu-view {
    color: black;
    i {
      color: blue;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .menu-delete {
    i {
      color: red;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}