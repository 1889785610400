.treeselect-group {
  .ant-select {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-weight: 400;
    border-radius: 4px;
  }

  .text-danger {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #d32f2f !important;
  }

  .ant-select-status-error {
    border-color: #d32f2f !important;
  }
}