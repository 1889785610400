.adm-tree {
  // .ant-tree-treenode {
  //   background-color: gray;
  // }
  .ant-tree-draggable-icon {
    padding: 10px 5px;
    // background-color: #f2f3f3;
  }
  .ant-tree-switcher {
    padding: 10px 5px;
    // background-color: #f2f3f3;
    // &:hover {
    //   background-color: #bdc1c1; 
    // }
  }
  .ant-tree-node-content-wrapper {
    padding: 10px 5px;
    background-color: #f2f3f3;
    &:hover {
      background-color: #bdc1c1; 
    }
  }
}