.ribbon-item {
  .ribbon-item-edit {
    color: black;
    i {
      color: green;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .ribbon-item-view {
    color: black;
    i {
      color: blue;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .ribbon-item-delete {
    i {
      color: red;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}