.vod-news-report {
  .switch-control {
    width: 150px;
    display: inline-block;
    button {
      width: 100%;
    }
  }

  .image-preview-container {
    position: relative;

    .ant-image {
      z-index: 100
    }
  }

  // .lastItemOverlay {
  //   z-index: 16;
  //   position: absolute;
  //   background: rgba(0, 0, 0, 0.5);
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   div {
  //     color: white;
  //     font-weight: 500;
  //     font-size: 20px;
  //   }
  // }

  .lastItemOverlayFirst {
    z-index: 11;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    top: 6px;
    width: 100px;
    height: 60px;
    right: -6px;
    left: 6px;
    bottom: -6px;
    border-radius: 4px;
  }

  .lastItemOverlaySecond {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 60px;
    top: 12px;
    right: -12px;
    left: 12px;
    bottom: -12px;
    border-radius: 4px;
  }
}

.description-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2px;
}