.transcode-add {
  .list-group-item {
    .name {
      padding: 10px 0;
      color: black;
      font-size: 14px;
    }
  }

  .folder-container {
    height: 60px;

    .text-primary {
      padding-top: 15px;
    }
  }

  .file-container {
    display: flex;
    flex-wrap: nowrap;
    height: 60px;

    .text-primary {
      margin-top: 15px;
      width: 50%;
    }
    .sort-container {
      width: 50%;

      .MuiFormControl-root {
        float: right;
      }
    }
  }
}