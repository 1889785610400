.vod-form {
  .switch-control {
    width: 150px;
    display: inline-block;
    button {
      width: 100%;
    }
  }

  .description-title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 2px;
  }
}