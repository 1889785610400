.comments {
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
  }

  thead[class*="ant-table-thead"] th{
    // background-color: #c5cbec;
    background-color: #e6e6e6;
  }

  .total-text-custom-text {
    position: absolute;
    bottom: 0;
  }
}